import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`EUV scanners`}</h4>
    <p>{`EUV scanners are the most advanced photolithography equipment currently used in mass semiconductor production. They are the only tools combining leading-edge precision (by producing light with small wavelengths) with high throughput (by using photomasks), and are necessary for mass-producing the most advanced logic chips. They are exclusively built by the Dutch firm ASML. A single EUV scanner costs well over $100 million, ships in 40 freight containers and contains about 100,000 individual parts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      